import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { LayoutBudget } from "@components/Structural/Budgets/Layout/Layout";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { SEO, RefinanceLoan2, RefinanceLoan2Mobile } from "@components";
import { useAuth } from "@hooks";

const ChooseApv: React.FC = () => {
  useAuth({ redirectIfNotLogged: true });

  const [page, setPage] = useState<number>(2);

  const isMobile = useMediaQuery("(max-width: 1300px)");

  return (
    <>
      <SEO />
      <LayoutBudget
        title="¿Debería refinanciar mi crédito?"
        subtitle="Tu resultado"
        onTurnBackClick={() => navigate(Route.finance)}
        // subtitleMobile="Paso 2/6: tus ingresos variables"
      >
        {isMobile ? (
          <>
            {page === 1 ? (
              <RefinanceLoan2Mobile setPage={setPage} />
            ) : (
              <RefinanceLoan2Mobile setPage={setPage} />
            )}
          </>
        ) : (
          <>
            {page === 1 ? (
              <RefinanceLoan2 setPage={setPage} />
            ) : (
              <RefinanceLoan2 setPage={setPage} />
            )}
          </>
        )}
      </LayoutBudget>
    </>
  );
};

export default ChooseApv;
